const ResinPage = () => {
  return (
    <>
      <h2>ResinPage</h2>
      <div>Resin tracking is coming soon.</div>
    </>
  );
};

export default ResinPage;
